<template>
    <el-dialog title="" :visible.sync="isShowDialog" width="500px" custom-class="resultDialog dataM" center
    :before-close="handleClose">
        <div class="tip">
            <img :src="result ? successSrc : errorSrc" alt="">
            <div class="title">MES 同步{{ message }}</div>
            <div v-if="(message === '失败')">失败原因：{{ reason }}</div>
            <div class="footer">
                <el-button type="primary" @click="againMES" v-if="(message === '失败')">重新同步</el-button>
                <el-button @click="handleClose" v-else>返回</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            successSrc: require('@/assets/images/dataManage/success.png'),
            errorSrc: require('@/assets/images/dataManage/error.png'),
        }
    },
    props: {
        isShowDialog: {
            type: Boolean,
            default: false
        },
        message: {
            type: String,
            default: ''
        },
        reason: {
            type: String,
            default: ''
        },
        result: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        againMES() {
            this.$emit('againMES')
        },
        handleClose() {
            this.$emit('closeDialog')
        }
    },
}
</script>

<style lang="scss" scoped>
.resultDialog {
    img {
        width: 70px;
        height: 70px;
    }

    .tip {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 0px 23px 0px;

        div {
            line-height: 40px;
            color: #B9B9B9;
            font-size: 16px;
        }

        .title {
            font-size: 24px;
            margin-top: 15px;
            color: #FFFFFF;
        }

        .el-button {
            color: #fff;
        }
    }
}
.footer{
    margin-top:15px;
}
</style>