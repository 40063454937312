<template>
  <div class="homewrap">
    <section class="home-ban">
      <section>
        <img src="@/assets/images/common/home1.png" />
      </section>
      <section class="home-box">
        <h2>{{ homeTitle }}</h2>
        <p>{{ homeContent }}</p>
      </section>
      <section class="home-list">
        <div class="mbox">
          <p>订单数</p>
          <h3>{{ ordersNumber }}</h3>
        </div>
        <div class="line"></div>
        <div class="mbox">
          <p>任务总数</p>
          <h3>
            {{ totalTaskNumber }}
          </h3>
        </div>
        <!-- <div class="line"></div> -->
        <!-- <div class="mbox">
          <p>日程数</p>
          <h3>{{ scheduleNumber }}</h3>
        </div> -->
      </section>
    </section>
    <section class="home-content">
      <div class="topbox">
        <!-- <div class="htbox">
          <img src="@/assets/images/common/home2.png" />
          <p>导入需求预测</p>
        </div> -->
        <!-- <el-upload action="" :auto-upload="false" :multiple="false" :show-file-list="false" :on-change="uploadByJsqd"
          :file-list="fileList">
          <div class="htbox">
            <img src="@/assets/images/common/home2.png" />
            <p>导入订单</p>
          </div>
        </el-upload> -->
        <div v-premiSub="'导出报表'" class="htbox" @click="ExportReport">
          <img src="@/assets/images/common/home6.png" />
          <p>导出报表</p>
        </div>
        <div v-premiSub="'生成计划'" class="htbox" @click="GeneratePlan">
          <img src="@/assets/images/common/home3.png" />
          <p>生成计划</p>
        </div>
        <div v-premiSub="'发布计划'" class="htbox" @click="issuePlanFn">
          <img src="@/assets/images/common/home4.png" />
          <p>发布计划</p>
        </div>
        <!-- <div class="htbox" @click="MESsync">
          <img src="@/assets/images/common/home5.png" />
          <p>MES同步</p>
        </div> -->
      </div>
    </section>
    <resultDialog :type="type" :resultVisible="resultVisible" @update:resultVisible="handleClose" @reUpload="reUpload"
      :fileName="fileName" :resultMsg="resultMsg" />
    <MES :isShowDialog="isShowDialog" @closeDialog="closeDialog" @againMES="againMES" :message="message"
      :result="result" />
    <issuePlan :exportsStatus="exportsStatus" :issueStatus="issueStatus" :isShowPlan="isShowPlan" @planError="planError" @planClose="planClose" @planOK="planOK" :title="title"
      :subTitle="subTitle" @ExportOK="ExportOK" @serialNumFn="serialNumFn" />
    <issueRes :message="planMessage" :isShowDialog="planIsShowDialog" :result="planResult" :serialNum="serialNum"
      @closePlanDialog="closePlanDialog" :errorReason="errorReason" />
  </div>
</template>

<script>
import { getInitialData } from "../../api/index"
import { importSale } from '@/api/orders'
import { getMesSync } from '@/api/gantt.js'
import resultDialog from '@/components/upload/resultDialog.vue'
import MES from '@/components/MES.vue'
import issuePlan from "@/components/issuePlan.vue"
import issueRes from '@/components/issueRes.vue'
// import premitest from "@/utils/premitest";

export default {
  name: "home",
  // directives: {
  //   premitest
  // },
  data() {
    return {
      ordersNumber: "",
      totalTaskNumber: "",
      completedTotalTaskNumber: "",
      // scheduleNumber: "",
      homeTitle: "欢迎来到里工高预APS先进排程！",
      homeContent:
        "里工APS是一种基于供应链管理和约束理论的先进排程系统。它通过同步考虑多种有限能力资源的约束，依据各种预设规则，通过复杂的智能化算法，反复模拟、优化、计算，最终给出最优的详细计划。",
      limitNum: 1,
      fileList: [],
      type: false,
      resultVisible: false,
      isShowDialog: false,
      isShowPlan: false,
      message: '',
      reason: '',
      result: false,
      planMessage: '',
      planIsShowDialog: false,
      planResult: false,
      title: '',
      subTitle: '',
      fileName: '',
      serialNum:'',
      resultMsg:'',
      errorReason:'',
      exportsStatus:false, // 是否从导出报表点进去
      issueStatus:false, // 是否从发布计划点进去
    }
  },
  components: {
    resultDialog,
    MES,
    issuePlan,
    issueRes,
  },
  mounted() {
    this.initInfo()
  },
  methods: {
    async initInfo() {
      await getInitialData().then(({ code, data, msg }) => {
        if (code === 0) {
          const {
            ordersNumber,
            totalTaskNumber,
            completedTotalTaskNumber,
            // scheduleNumber,
          } = data
          this.ordersNumber = ordersNumber
          this.totalTaskNumber = totalTaskNumber
          this.completedTotalTaskNumber = completedTotalTaskNumber
          // this.scheduleNumber = scheduleNumber
        } else {
          this.$message.error(msg)
        }
      })
    },
    // 导出报表
    ExportReport() {
      this.isShowPlan = true
      this.title = '导出报表'
      this.subTitle = '报表类型'
      this.exportsStatus = true
      this.issueStatus = false
    },
    ExportOK() {
      this.isShowPlan = false
    },
    // 发布计划
    issuePlanFn() {
      this.isShowPlan = true
      this.title = '发布计划'
      this.subTitle = '排程流水号'
      this.exportsStatus = false
      this.issueStatus = true
    },
    planClose() {
      this.isShowPlan = false
    },
    serialNumFn(val){
      this.serialNum = val
    },
    planOK() {
      // 发布计划成功
      this.isShowPlan = false
      this.planIsShowDialog = true
      this.planMessage = '成功'
      this.planResult = true
    },
    planError(val) {
      // 发布计划失败
      this.isShowPlan = false
      this.planIsShowDialog = true
      this.planMessage = '失败'
      this.planResult = false
      console.log('计划发布失败原因：',val)
      this.errorReason = val
    },
    closePlanDialog() {
      this.planIsShowDialog = false
    },
    // MES同步
    async MESsync() {
      const res = await getMesSync()
      if (res.data === 'ok') {
        this.message = '成功'
        this.result = true
      } else {
        this.message = '失败'
        this.reason = res.msg
        this.result = false
      }
      this.isShowDialog = true
    },
    closeDialog() {
      this.isShowDialog = false
    },
    againMES() {
      this.MESsync()
    },
    // 导入订单
    beforeAvatarUpload(file) {
      let fileArr = file.name.split('.')
      let suffix = fileArr[fileArr.length - 1]
      if (!/(xls|xlsx)/i.test(suffix)) {
        this.resultMsg = '文件格式不正确'
        return false
      }
      if (file.size > 10 * 1024 * 1024) {
        this.resultMsg = '文件过大，请上传小于10MB的文件〜'
        return false
      }
      return true
    },
    uploadByJsqd(file) {
      if (this.beforeAvatarUpload(file)) {
        this.fileList.name = file.name
        this.fileName = file.name
        this.fileList.url = ''
        let formdata = new FormData()
        formdata.append('file', file.raw)
        importSale(formdata).then((res) => {
          if (res.data === 'ok') {
            this.resultVisible = true
            this.type = true
          } else {
            this.resultMsg = res.msg
            this.resultVisible = true
            this.type = false
          }
        })
      }
    },
    handleClose() {
      this.resultVisible = false
    },
    reUpload() {
      let temp = document.querySelectorAll('.el-upload')
      temp[0].click()
    },
    GeneratePlan() {
      this.$router.push({
        path: '/gantt'
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.home-ban {
  display: flex;
  padding: 32px 32px;
  background: #232323;
  // justify-content: space-between;
}

.home-box {
  text-align: left;
  margin: 0 40px;
  width: 62%;

  h2 {
    font-size: 28px;
  }

  p {
    width: 780px;
    font-size: 14px;
  }
}

.home-list {
  display: flex;
  align-items: center;

  .line {
    border-right: 1px solid #e8e8e8;
    height: 50px;
    width: 2px;
    margin: 0 10px;
  }
}

.home-ban section {
  flex: 0 1 1;
  // flex-grow: 1 2 1;
}

.mbox {
  width: 100px;
  margin: 0 20px;

  h3 {
    font-size: 40px;
    color: #2deb79;

    .mdeil {
      font-size: 18px;
      color: #b9b9b9;
    }
  }

  p {
    font-size: 18px;
  }
}

.home-content {
  padding: 30px;
}

.topbox {
  display: grid;
  grid-template-columns: 400px 400px 400px;
  grid-column-gap: 90px;
  grid-row-gap: 30px;
  // display: flex;
  // flex-wrap: wrap;
}

.htbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #232323;
  width: 400px;
  height: 124px;
  border-radius: 8px;
  padding: 30px;
  // margin: 0 30px 30px 0;
  cursor: pointer;

  img {
    width: 48px;
    height: 48px;
    margin-right: 20px;
  }
}
</style>
