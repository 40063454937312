<template>
  <el-dialog
    title=""
    :visible.sync="visible"
    custom-class="resultDialog dataM"
    width="520px"
    @close="handleClose"
    center
    >
    <div class="tip">
      <template v-if="importResult">
        <img :src="type ? successSrc : errorSrc"/>
        <div class="title">导入{{name}}{{type ? '成功' : '失败'}}</div>
        <div>{{type ? `文件：${fileName}` : `失败原因：${resultMsg}`}}</div>
        <div class="footer">
          <el-button v-if="!type" @click="reUpload" type="primary" style="color:#FFF">重新导入</el-button>
          <el-button @click="handleClose">{{type ? '返回列表' : '取消'}}</el-button>
        </div>
      </template>
      <template v-else>
        <img :src="errorSrc"/>
        <div class="title">已有相同颜色存在，请重新选择</div>
        <div class="footer">
          <el-button @click="handleClose" type="primary" style="color:#FFF">确定</el-button>
        </div>
      </template>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name:'resultDialog',
  props:{
    type:{
      require:true,
      default:true
    },
    resultVisible:{
      require:true,
      default:false
    },
    name:{
      default:'Excel'
    },
    fileName:{
      default:'Excel'
    },
    resultMsg:{
      default:''
    },
    // 是否为导入结果弹窗
    importResult:{
      type:Boolean,
      default:true
    }
  },
  data(){
    return{
      successSrc:require('@/assets/images/dataManage/success.png'),
      errorSrc:require('@/assets/images/dataManage/error.png'),
    }
  },
  computed:{
    visible:{
      get(){
        return this.resultVisible
      },
      set(){
        
      }
    }
  },
  methods:{
    handleClose(){
      this.$emit('update:resultVisible',false)
    },
    reUpload(){
      this.$emit('update:resultVisible',false)
      this.$emit('reUpload')
    }
  }
}
</script>

<style scoped lang="scss">
.resultDialog{
  img{
    width: 70px;
    height: 70px;
  }
  .tip{
    display:flex;
    flex-direction:column;
    align-items:center;
    padding: 10px 0px 30px 0px;
    div{
      line-height: 50px;
      color: #B9B9B9;
    }
    .title{
      font-size: 24px;
      margin-top: 20px;
      color: #FFFFFF;
    }
    .el-button{
      color: #B9B9B9;
    }
  }
}
</style>