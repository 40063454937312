<template>
    <div>
        <el-dialog :title=title :visible.sync="isShowPlan" width="360px" custom-class="resultDialog dataM"
            :before-close="handleClose">
            <div class="tip">
                <div class="title" v-if="option2Status">{{ subTitle }}</div>
                <el-select v-model="value2" placeholder="请选择" filterable @change="newNeedFn" v-if="option2Status">
                    <el-option v-for="(item, index) in options2" :key="index" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <div class="title" v-show="newNeed">排程流水号</div>
                <!-- 导出报表 -->
                <el-select v-model="exportValue" v-show="newNeed" filterable placeholder="请选择" v-if="exportsStatus">
                    <el-option v-for="(item, index) in allOptions" :key="index" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <!-- 生成计划 -->
                <el-select v-if="issueStatus" v-model="value" filterable placeholder="请输入排程流水号">
                    <el-option v-for="item in restaurants" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <div class="footer">
                    <el-button @click="handleClose" style="color:#B9B9B9">取消</el-button>
                    <el-button type="primary" @click="planOK">确定</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { allData, scheduledPlanRelease } from '@/api/plan.js'
import { exportMaterialShortageInfo, exportEquipmentMaintenancePlan, exportSchedulingPlan, exportWorkOrderDeliveryPlan, exportDailyOutputStatistics, exportEquipmentPlannedTurnoverRate } from '@/api/index.js'
export default {
    data() {
        return {
            message: '',
            reason: '',
            allOptions: [{ //全部排程流水号
                value: '',
                label: '',
            }],
            exportValue: '',
            value: '',
            value2: '',
            SchedulingNum: null,
            temp: null,
            options2: [{  //导出报表
                value: '主资源生产计划表',
                label: '主资源生产计划表'
            }, {
                value: '工单交货计划表',
                label: '工单交货计划表'
            }, {
                value: '每日产出统计报表',
                label: '每日产出统计报表'
            }, {
                value: '设备稼动率',
                label: '设备稼动率'
            }, 
            // {
            //     value: '欠料表',
            //     label: '欠料表'
            // }, {
            //     value: '设备保养计划',
            //     label: '设备保养计划'
            // }
        ],
            option2Status: false,
            restaurants: [],
            timeout: null,
            newNeed: true
        }
    },
    watch: {
        isShowPlan() {
            if (this.isShowPlan) {
                this.getScheduling()
                this.newNeed = true
            }
        },
        title: {
            handler() {
                if (this.title === '导出报表') {
                    this.option2Status = true
                } else {
                    this.option2Status = false
                }
            },
            immediate: true
        }
    },
    props: {
        isShowPlan: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        subTitle: {
            type: String,
            default: ''
        },
        exportsStatus: {
            type: Boolean,
            default: false
        },
        issueStatus: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        // 获取排程记录
        async getScheduling() {
            // 过滤已发布的流水号--给到发布计划
            const res = await allData()
            let temp = res.data.filter(v => v.scheduledStatus === 1)
            this.restaurants = temp.map(v => ({
                value: v.serialNumber,
                label: v.serialNumber
            }))
            // 全部排程流水号--给到导出报表
            this.allOptions = res.data.map(v => ({
                value: v.serialNumber,
                label: v.serialNumber
            }))
        },
        // 关闭弹框
        handleClose() {
            this.value = ''
            this.value2 = ''
            this.exportValue = ''
            this.$emit('planClose')
        },
        newNeedFn(val) {
            if (val === '工单交货计划表' || val === '每日产出统计报表' || val === '设备稼动率') {
                this.newNeed = false
            } else {
                this.newNeed = true
            }
        },
        // 点击确定
        async planOK() {
            if (this.title === '导出报表') {
                if (this.value2 === '') {
                    this.$message.warning('请选择报表类型！')
                    return
                }
                if (this.value2 === '主资源生产计划表' || this.value2 === '欠料表' || this.value2 === '设备保养计划') {
                    if (this.exportValue === '') {
                        this.$message.warning('请选择排程流水号！')
                        return
                    }
                }
                let params = {
                    serialNumber: this.exportValue
                }
                switch (this.value2) {
                    case '主资源生产计划表':
                        await exportSchedulingPlan(params).then(res => this.exportFn(res, this.value2))
                        break
                    case '工单交货计划表':
                        await exportWorkOrderDeliveryPlan().then(res => this.exportFn(res, this.value2))
                        break
                    case '每日产出统计报表':
                        await exportDailyOutputStatistics().then(res => this.exportFn(res, this.value2))
                        break
                    case '设备稼动率':
                        await exportEquipmentPlannedTurnoverRate().then(res => this.exportFn(res, this.value2))
                        break
                    case '排程分析':
                        break
                    case '异常分析':
                        break
                    case '欠料表':
                        await exportMaterialShortageInfo(params).then(res => this.exportFn(res, this.value2))
                        break
                    default:
                        // 设备保养计划
                        await exportEquipmentMaintenancePlan(params).then(res => this.exportFn(res, this.value2))
                        break
                }
                this.$emit('ExportOK')
                this.value2 = ''
                this.exportValue = ''
            } else {
                if (this.restaurants.length === 0) {
                    this.$message.warning('暂无排程流水号！')
                    return
                }
                if (this.value === '') {
                    this.$message.warning('请选择排程流水号！')
                    return
                }
                const res = await scheduledPlanRelease({ serialNumber: this.value })
                // 将选中的流水号传回父组件
                this.$emit('serialNumFn', this.value)
                if (res.data === 'ok') {
                    this.$emit('planOK')
                } else {
                    // 发布计划失败，并告知原因
                    this.$emit('planError', res.msg)
                }
                this.value = ''
            }
        },
        exportFn(res, val) {
            this.filename = `${val}.xlsx`
            this.url = window.URL.createObjectURL(res)
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = this.url
            link.setAttribute('download', this.filename)
            document.documentElement.appendChild(link)
            link.click()
            document.documentElement.removeChild(link)
            this.$message.success('导出成功！')
        }
    }
}
</script>

<style lang="scss" scoped>
.resultDialog {
    img {
        width: 70px;
        height: 70px;
    }

    .tip {
        padding: 3px 7px 3px 7px;

        div {
            line-height: 40px;
            color: #B9B9B9;
        }

        .title {
            font-size: 16px;
            text-align: left;
            color: #E8E8E8;
        }

        .el-button {
            color: #fff;
            width: 80px;
            height: 40px;
        }
    }
}

.footer {
    text-align: right;
    margin-top: 12px;
}

::v-deep .el-dialog__header {
    text-align: left;
    padding-top: 25px !important;
    padding-left: 25px !important;

    .el-dialog__title {
        font-size: 22px;
    }
}

::v-deep .el-select {
    width: 100%;
    margin-bottom: 8px;
}

::v-deep .el-autocomplete {
    width: 100%;
}
</style>